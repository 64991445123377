<template>
  <base-list :items="items" />
</template>

<script>
import {
  genSubheaderItem,
  genDefaultItem,
  genChipsItem,
  genMultilineItem,
  hideIfEmpty,
  genListButton,
  genServiceNameItem
} from '@/utils/list-generators';
import { computed, inject } from '@vue/composition-api';

import { useCoordinatesItemMenu } from './coordinatesItemMenu';
import { useMonitoringItemMenu } from './monitoringItemMenu';
import { useGeoItemMenu } from './geoItemMenu';

import { useMonitoringItems } from '@/modules/objects/compositions/monitoring-items';
import { useGeoItems } from '@/modules/objects/compositions/geo-items';

import { formatCoordinates } from '@/utils';
import { useCopy } from '@/compositions/copy';
import { useLandmarks } from '@/modules/landmarks/compositions/landmarks';
import { useGeozones } from '@/modules/geozones/compositions/geozones';
import { trimName } from '@/modules/object-types/api/type';
import { useManagementItems } from '@/modules/objects/compositions/management-items';
import { useManagementItemMenu } from '@/modules/objects/ui/object-card/general/managementItemMenu';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { useGeotagItemMenu } from '@/modules/geotags/compositions/geotagItemMenu';
import { useMap } from '@/compositions/map';
import { useCustomPropertyItemMenu } from '@/modules/objects/ui/object-card/general/customPropertyItemMenu';
import router from '@/router';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import { genAccessListFragment } from '@/modules/access/compositions/access-list-fragment';

export default {
  name: 'ObjectCardGeneral',
  setup(props, { root }) {
    const object = inject('object');

    const { genMenu: genGeotagMenu } = useGeotagItemMenu();
    const { genMenu: genCoordinatesItemMenu } = useCoordinatesItemMenu();
    const { genMenu: genMonitoringItemMenu } = useMonitoringItemMenu();
    const { genMenu: genManagementItemMenu } = useManagementItemMenu();
    const { genMenu: genCustomPropertyItemMenu } = useCustomPropertyItemMenu();
    const { genMenu: genGeoItemMenu } = useGeoItemMenu();
    const { copyWithAlert } = useCopy();
    const { list: landmarks } = useLandmarks();
    const { list: geozones } = useGeozones();
    const { getGeotagById } = useGeotags();
    const {
      markers: { zoomInAndSelectMarker }
    } = useMap();
    const { getNextRoute } = useHistoryStack();

    const getGeoObjectById = objectId => {
      return [...landmarks.value, ...geozones.value].find(
        geoObject => geoObject.id === objectId
      );
    };

    function genManagementItem(item) {
      const icon = !item.linked ? '$link_off' : '';
      return genDefaultItem({
        hardLinked: item.hardLinked,
        title: item.infoName,
        icon,
        actions: genManagementItemMenu(item, object.value.id)
      });
    }

    function genCustomPropertyItem(item) {
      return genDefaultItem({
        title: `${item.property}: ${item.type}`,
        subTitle: String(item.value),
        actions: genCustomPropertyItemMenu(item)
      });
    }

    function genMonitoringItem(item) {
      const alert = item.stateAlert === 'TRIGGERED';
      const hasAlert = item.stateAlert !== 'OFF';
      const hasLink = !!item.linked;
      const icon = !hasLink
        ? '$link_off'
        : !hasAlert
        ? ''
        : alert
        ? '$alert_on'
        : '$alert';
      const value = item.stateValue;
      return genDefaultItem(
        {
          hardLinked: item.hardLinked,
          title: item.infoName,
          subTitle: `${value} ${item.commonUnits}`,
          iconColor: alert && hasLink ? 'red' : undefined,
          icon,
          invert: true,
          actions: genMonitoringItemMenu(item, object.value.id)
        },
        {
          click: () => copyWithAlert(value)
        }
      );
    }

    function genGeoItem(item) {
      const alert = item.stateAlert === 'TRIGGERED';
      const hasAlert = item.stateAlert !== 'OFF';
      const hasLink = !!item.linked;
      let subTitle = '';
      if (item.stateSource !== 'n/a') {
        const geoObject = getGeoObjectById(item.stateSource);
        if (geoObject) {
          subTitle = geoObject.name;
        }
      }
      const icon = !hasLink
        ? '$link_off'
        : !hasAlert
        ? ''
        : alert
        ? '$alert_on'
        : '$alert';
      return genDefaultItem(
        {
          hardLinked: item.hardLinked,
          title: item.infoName,
          subTitle,
          iconColor: alert && hasLink ? 'red' : undefined,
          icon,
          invert: true,
          actions: genGeoItemMenu(item, object.value.id)
        },
        {
          click: () => copyWithAlert(item.stateSource)
        }
      );
    }

    const {
      load: loadMonitoringItems,
      list: monitoringItemsList
    } = useMonitoringItems({ object: object.value });

    const {
      load: loadManagementItems,
      list: managementItemsList
    } = useManagementItems({ object: object.value });

    const { load: loadGeoItems, list: geoItemsList } = useGeoItems({
      object: object.value
    });

    loadMonitoringItems();
    loadManagementItems();
    loadGeoItems();

    const items = computed(() => [
      ...hideIfEmpty(
        monitoringItemsList.value.map(i => genMonitoringItem(i)).length,
        [
          genSubheaderItem('Monitoring items'),
          ...monitoringItemsList.value.map(i => genMonitoringItem(i))
        ]
      ),
      ...hideIfEmpty(
        managementItemsList.value.map(i => genManagementItem(i)).length,
        [
          genSubheaderItem('Management items'),
          ...managementItemsList.value.map(i => genManagementItem(i))
        ]
      ),
      ...hideIfEmpty(object.value?.customProperties?.length, [
        genSubheaderItem('Properties'),
        ...object.value?.customProperties?.map(i => genCustomPropertyItem(i))
      ]),
      ...hideIfEmpty(geoItemsList.value.length, [
        genSubheaderItem('Geo'),
        ...geoItemsList.value.map(i => genGeoItem(i))
      ]),
      genSubheaderItem('Position'),
      genDefaultItem({
        title: 'Center',
        subTitle: formatCoordinates(object.value.positionPoint.value, false),
        icon: '$marker_set',
        invert: true,
        actions: genCoordinatesItemMenu(
          object.value.positionPoint,
          object.value.id
        )
      }),
      genDefaultItem({
        title: 'Geotag',
        subTitle: getGeotagById(object.value.positionGeotagId.value)?.name,
        icon: '$geotag',
        invert: true,
        actions: genGeotagMenu(object.value.positionGeotagId, object.value.id)
      }),
      ...hideIfEmpty(!root.$vuetify.breakpoint.mobile, [
        genListButton(
          { label: 'Show on the map', outlined: true, color: 'primary' },
          { click: () => zoomInAndSelectMarker(object.value.id) }
        )
      ]),
      ...hideIfEmpty(object.value.objectGroups.length, [
        genSubheaderItem('Object groups'),
        genChipsItem(object.value.formattedObjectGroups)
      ]),
      ...genAccessListFragment({
        readerGroup: object.value.userGroupByReadergroup.groupName,
        userGroup: object.value.userGroupByUsergroup.groupName,
        editorGroup: object.value.userGroupByEditorgroup.groupName
      }),
      genSubheaderItem('Service'),
      genDefaultItem(
        {
          title: object.value.id,
          icon: '$uuid'
        },
        {
          click: () => copyWithAlert(object.value.id)
        }
      ),
      genDefaultItem(
        {
          title: trimName(object.value.schema).name,
          icon: '$schema'
        },
        {
          click: async () => {
            await router.push(
              getNextRoute({
                component: componentAliases.tc,
                props: {
                  typeId: object.value.schema?.id
                }
              })
            );
          }
        }
      ),
      genServiceNameItem(object.value.name, {
        click: () => copyWithAlert(object.value.name)
      }),
      ...hideIfEmpty(object.value.description, [
        genSubheaderItem('Description'),
        genMultilineItem({
          text: object.value.description
        })
      ])
    ]);

    return { items };
  }
};
</script>

<style></style>
